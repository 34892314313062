import { useState } from "react";

/**
 * Used for storing input values to get rid of those onchange callbacks
 * @param {any} initialState
 * @returns {[any, (event: ChangeEvent<HTMLInputElement>) => void, (value: any) => void]}  [state, eventHandler, setState]
 * @example
 * function Demo() {
 *   const [a, changeA, setA] = useInputState(1);
 *   const [b, changeB, setB] = useInputState(2);
 *
 *   return (
 *     <>
 *       <input type="number" value={a} onChange={changeA} />
 *       <input type="number" value={b} onChange={changeB} />
 *
 *       <p>{a} + {b} = {a + b}</p>
 *     </>
 *   );
 * }
 */
export default function useInputState(initialState) {
    const [state, setState] = useState(initialState);

    return [
        state,
        (event) => setState(event.target.value),
        setState,
    ];
}
