import { useEffect, useState } from 'react';
import { DayPicker, MonthPicker, YearPicker } from 'react-dropdown-date';
import { useIntl } from 'react-intl';

import { isValidNumber } from 'services/validation';

import './index.scss';

function DateMonthYearPicker({ defaultValue, onChange, errors }) {
    const [date, setDate] = useState({ year: '', month: '', day: '' });
    const [dateIncomplete, setDateIncomplete] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        if (!defaultValue) {
            return;
        }

        setDate(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        let { year, month, day } = date;

        month = month != '' ? parseInt(month, 10) + 1 : month;
        year = parseInt(year, 10);
        day = parseInt(day, 10);

        let formattedDate;

        if (!isValidNumber(year) || !isValidNumber(month) || !isValidNumber(day)) {
            setDateIncomplete(true);
        }

        if (isValidNumber(year) && isValidNumber(month) && isValidNumber(day)) {
            setDateIncomplete(false);

            formattedDate = `${ year }-${ month }-${ day }`;
        }

        onChange?.(formattedDate, dateIncomplete, { year, month, day });
    }, [date]);

    return (
        <div className="dmy-picker">
            <DayPicker
                defaultValue={intl.formatMessage({ id: 'birthday.day-placeholder' })}
                year={date.year} // mandatory
                month={date.month} // mandatory
                endYearGiven // mandatory if end={} is given in YearPicker
                name="day"
                value={date.day} // mandatory
                onChange={(day) => {
                    // mandatory
                    setDate((prev) => ({ ...prev, day }));
                }}
                id="day"
                classes={`dropdown ${ errors?.day ? 'error' : '' }`}
                optionClasses="option"
            />

            <MonthPicker
                defaultValue={intl.formatMessage({ id: 'birthday.month-placeholder' })}

                // numeric // to get months as numbers
                endYearGiven // mandatory if end={} is given in YearPicker
                name="month"
                numeric
                year={date.year} // mandatory
                value={date.month} // mandatory
                onChange={(month) => {
                    // mandatory
                    setDate((prev) => ({ ...prev, month }));
                }}
                id="month"
                classes={`dropdown ${ errors?.month ? 'error' : '' }`}
                optionClasses="option"
            />

            <YearPicker
                defaultValue={intl.formatMessage({ id: 'birthday.year-placeholder' })}

                // start={1900} // default is 1900
                // end={2020} // default is current year
                name="year"
                reverse // default is ASCENDING
                value={date.year} // mandatory
                onChange={(year) => {
                    // mandatory
                    setDate((prev) => ({ ...prev, year }));
                }}
                id="year"
                classes={`dropdown ${ errors?.year ? 'error' : '' }`}
                optionClasses="option"
            />
        </div>
    );
}

export default DateMonthYearPicker;
