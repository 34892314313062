import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

function Male() {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3571 2.80465L15.9796 8.18219C17.2856 9.74895 18.0714 11.7649 18.0714 13.9643C18.0714 18.9545 14.026 23 9.03571 23C4.04548 23 0 18.9545 0 13.9643C0 8.97405 4.04548 4.92857 9.03571 4.92857C11.235 4.92857 13.2511 5.7145 14.8178 7.0204L20.1954 1.64286H15.6071C15.1536 1.64286 14.7857 1.275 14.7857 0.821429C14.7857 0.367853 15.1536 0 15.6071 0H21.3571C22.2645 0 23 0.735472 23 1.64286V7.39286C23 7.84643 22.6321 8.21429 22.1786 8.21429C21.725 8.21429 21.3571 7.84643 21.3571 7.39286V2.80465ZM9.03571 21.3572C13.1186 21.3572 16.4286 18.0472 16.4286 13.9643C16.4286 9.88149 13.1186 6.57149 9.03571 6.57149C4.95286 6.57149 1.64286 9.88149 1.64286 13.9643C1.64286 18.0472 4.95286 21.3572 9.03571 21.3572Z"
                fill="#353D4B"
            />
        </svg>
    );
}

function Female() {
    return (
        <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.72727 15.7802V18.6875H10.9091C11.3107 18.6875 11.6364 19.0094 11.6364 19.4062C11.6364 19.8031 11.3107 20.125 10.9091 20.125H8.72727V22.2812C8.72727 22.6781 8.40158 23 8 23C7.59842 23 7.27273 22.6781 7.27273 22.2812V20.125H5.09091C4.68932 20.125 4.36364 19.8031 4.36364 19.4062C4.36364 19.0094 4.68932 18.6875 5.09091 18.6875H7.27273V15.7802C3.19522 15.4172 0 12.0304 0 7.90627C0 3.5398 3.58177 0 8 0C12.4182 0 16 3.5398 16 7.90627C16 12.0305 12.8048 15.4172 8.72727 15.7802ZM8 14.375C11.6149 14.375 14.5455 11.4787 14.5455 7.90622C14.5455 4.33371 11.6149 1.43745 8 1.43745C4.38514 1.43745 1.45455 4.33371 1.45455 7.90622C1.45455 11.4787 4.38514 14.375 8 14.375Z"
                fill="#353D4B"
            />
        </svg>
    );
}

function CheckBox({ defaultValue, onChange }) {
    const [select, setSelect] = useState(defaultValue);

    useEffect(() => setSelect(defaultValue), [defaultValue]);

    useEffect(() => onChange?.(select), [select]);

    return (
        <div className="d-flex align-items-center">
            <label
                onClick={() => {
                    setSelect('female');
                }}
                className={`d-flex align-items-center radio-box  ${ styles['radio-box'] }`}
            >
                <div className={`${ styles['svg-wrapper'] } ${ select === 'female' ? styles.selected : '' } bg-gray mr-2`}>
                    <Female />
                </div>
                <div className={`color-white ${ styles.description } mr-2`}>
                    <FormattedMessage id="female" />
                </div>
            </label>
            <label
                onClick={() => {
                    setSelect('male');
                }}
                className={`d-flex align-items-center radio-box  ${ styles['radio-box'] }`}
            >
                <div className={`${ styles['svg-wrapper'] } ${ select === 'male' ? styles.selected : '' } bg-gray mr-2`}>
                    <Male />
                </div>
                <div className={`color-white ${ styles.description } mr-2`}>
                    <FormattedMessage id="male" />
                </div>
            </label>
            <input type="hidden" name="gender" value={select} />
        </div>
    );
}

export default CheckBox;
