import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProfileInfo } from 'store/profile/profile.action';

export default function useGetProfileInfo() {
    const dispatch = useDispatch();
    const {
        info, infoError, InfoIsLoading, isUpdated, isAvatarUpdated,
    } = useSelector((state) => state.profileReducer);

    useEffect(() => {
        if (InfoIsLoading) {
            return;
        }

        if (isEmpty(info) || isUpdated || isAvatarUpdated) {
            dispatch(getProfileInfo());
        }
    }, [isUpdated, isAvatarUpdated, info]);

    return { info, error: infoError, isLoading: InfoIsLoading };
}
