export const ImageMimeType = /image\/(png|jpg|jpeg)/gm;

export const TunisiaStates = [
    {
        stateName: 'Ariana',
        id: 'ariana',
    },
    {
        stateName: 'Beja',
        id: 'beja',
    },
    {
        stateName: 'Ben Arous',
        id: 'ben-arous',
    },
    {
        stateName: 'Bizerte',
        id: 'bizerte',
    },
    {
        stateName: 'Gabes',
        id: 'gabes',
    },
    {
        stateName: 'gafsa',
        id: 'gafsa',
    },
    {
        stateName: 'Jendouba',
        id: 'jendouba',
    },
    {
        stateName: 'Kairouan',
        id: 'kairouan',
    },
    {
        stateName: 'Kasserine',
        id: 'kasserine',
    },
    {
        stateName: 'Kebili',
        id: 'kebili',
    },
    {
        stateName: 'Kef',
        id: 'kef',
    },
    {
        stateName: 'Mahdia',
        id: 'mahdia',
    },
    {
        stateName: 'Manouba',
        id: 'manouba',
    },
    {
        stateName: 'Medenine',
        id: 'medenine',
    },
    {
        stateName: 'Monastir',
        id: 'monastir',
    },
    {
        stateName: 'Nabeul',
        id: 'nabeul',
    },
    {
        stateName: 'Sfax',
        id: 'sfax',
    },
    {
        stateName: 'Sidi Bouzid',
        id: 'sidi-Bouzid',
    },
    {
        stateName: 'Siliana',
        id: 'siliana',
    },
    {
        stateName: 'Sousse',
        id: 'sousse',
    },
    {
        stateName: 'Tataouine',
        id: 'tataouine',
    },
    {
        stateName: 'Tozeur',
        id: 'tozeur',
    },
    {
        stateName: 'Tunis',
        id: 'tunis',
    },
    {
        stateName: 'Zaghouan',
        id: 'zaghouan',
    },
];
