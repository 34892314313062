import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import DateMonthYearPicker from 'components/form/date-month-year-picker';
import FormError from 'components/form/form-error';
import TAOOHelmet from 'components/helmet/Helmet';
import Loader from 'components/loaders/loader';
import ProfileEditAvatar from 'components/profile/profile-edit-avatar';
import useToast from 'components/widgets/toast/useToast';
import { domain } from 'constants/api';
import { LoaderInButtonFillColor, MaxUploadSize } from 'constants/defaults';
import useLocale from 'hooks/locale/useLocale';
import useGetProfileInfo from 'hooks/profile/useGetProfileInfo';
import useUpdateProfileAvatar from 'hooks/profile/useUpdateProfileAvatar';
import useUpdateProfileInfo from 'hooks/profile/useUpdateProfileInfo';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { formatBytes } from 'services/conversion';

import ChangeEmailOrPhonePopup from './change-email-or-phone/ChangeEmailOrPhonePopup';
import ChangePassword from '../change-password';
import RadioBox from './check-box';
import { ImageMimeType, TunisiaStates } from './defaults';
import styles from './index.module.scss';

function EditProfile() {
    const intl = useIntl();
    const { triggerToast } = useToast();
    const language = useLocale();
    const navigate = useNavigate();
    const { info: initialData } = useGetProfileInfo();
    const {
        update: updateProfile,
        isLoading: updateProfileLoader,
        isUpdated: isProfileUpdated,
    } = useUpdateProfileInfo();
    const { update: updateProfileAvatar } = useUpdateProfileAvatar();

    const [dateIncomplete, setDateIncomplete] = useState(false);

    const [formError, setFormError] = useState('');
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [gender, setGender] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [birthday, setBirthday] = useState('');

    useEffect(() => {
        if (!initialData) {
            return;
        }

        setFirstName(initialData.firstname);
        setLastName(initialData.lastname);
        setBirthday(initialData.birthday || '');
        setCity(initialData.city || '');

        if (initialData.gender) {
            setGender(initialData.gender === 'MALE' ? 'male' : 'female');
        }

        if (initialData.avatar) {
            setFileDataURL(`${ domain }/uploads/${ initialData.avatar }`);
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { files } = e.target;

        if (files.length > 0) {
            const file = files[0];

            const fileSize = formatBytes({ bytes: file.size });

            if (fileSize > MaxUploadSize) {
                triggerToast(
                    intl.formatMessage({
                        id: 'error.file-upload-size',
                    }),
                    'error',
                );

                return;
            }

            if (!file.type.match(ImageMimeType)) {
                triggerToast(
                    intl.formatMessage({
                        id: 'error.edit-profile.image-type',
                    }),
                    'error',
                );

                return;
            }

            setFile(file);

            const formData = new FormData();

            formData.append('avatar', file);

            updateProfileAvatar(formData);

            triggerToast(
                intl.formatMessage({
                    id: 'edit-profile.avatar-updated',
                }),
                'success',
            );
        }
    };

    useEffect(() => {
        let fileReader;
        let isCancel = false;

        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;

                if (result && !isCancel) {
                    setFileDataURL(result);
                }
            };

            fileReader.readAsDataURL(file);
        }

        return () => {
            isCancel = true;

            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [file]);

    const onDateChange = (formattedDate, isDateIncomplete, date) => {
        const { year, month, day } = date;

        if (!year && !month && !day) {
            return;
        }

        setDateIncomplete(isDateIncomplete);

        if (formattedDate) {
            setBirthday(formattedDate);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!firstName || !lastName) {
            setFormError('error.edit-profile.required-inputs');

            return;
        }

        if (dateIncomplete) {
            setFormError('error.edit-profile.birthday-incomplete');

            return;
        }

        setFormError(null);

        const selectedGender = (() => {
            if (gender === 'female') {
                return 'FEMALE';
            }

            if (gender === 'male') {
                return 'MALE';
            }

            return null;
        })();

        updateProfile({
            firstname: firstName,
            lastname:  lastName,
            city,
            gender:    selectedGender,
            birthday,
        });
    };

    useEffect(() => {
        if (isProfileUpdated) {
            triggerToast(intl.formatMessage({id: 'edit-profile.update-success'}), 'success');

            navigate(All_PATHS_NAMESPACES[language].profile.path);
        }
    }, [isProfileUpdated]);

    useEffect(() => {
        if (formError) {
            triggerToast(
                intl.formatMessage({
                    id: formError,
                }),
                'error',
            );
        }
    }, [formError]);

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showChangeEmailPhone, setShowChangeEmailPhone] = useState(false);

    const openChangePassword = () => setShowChangePassword(true);
    const closeChangePassword = () => setShowChangePassword(false);

    const openChangeEmailPhone = () => setShowChangeEmailPhone(true);
    const closeChangeEmailPhone = () => setShowChangeEmailPhone(false);

    return (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.edit-profile' })} />

            <div className="flex-grow-1">
                <form onSubmit={handleSubmit}>
                    <ProfileEditAvatar image={fileDataURL} />
                    <div className="file-uploader-container">
                        <label
                            htmlFor="profile_image"
                            className={`${ styles.upload_btn } bg-gray color-white mx-auto mt-3`}
                        >
                            <FormattedMessage id="upload.avatar" />
                        </label>
                        <input id="profile_image" onChange={handleChange} type="file" accept="image/*" hidden />
                    </div>
                    <div className={styles.popupChange}>
                        <div onClick={openChangeEmailPhone}>
                            <FormattedMessage id="profile.change-email-or-phone" />
                        </div>
                        <div onClick={openChangePassword}>
                            <FormattedMessage id="profile.change-password" />
                        </div>
                    </div>
                    <FormattedMessage id="edit-profile.name_placeholder">
                        {(msg) => (
                            <>
                                <label className="color-white mb-2" htmlFor="name">
                                    <FormattedMessage id="name" />
                                </label>
                                <div className="input-wrapper">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder={msg}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="edit-profile.lastname_placeholder">
                        {(msg) => (
                            <>
                                <label className="color-white mb-2" htmlFor="lastname">
                                    <FormattedMessage id="lastName" />
                                </label>
                                <div className="input-wrapper">
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        placeholder={msg}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="edit-profile.state">
                        {(msg) => (
                            <>
                                <label className="color-white mb-2" htmlFor="state">
                                    {msg}
                                </label>
                                <div className="input-wrapper">
                                    <select
                                        id="state"
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city || 'default'}
                                    >
                                        <FormattedMessage id="edit-profile.select-state">
                                            {(msg1) => (
                                                <option value="default" disabled>
                                                    {msg1}
                                                </option>
                                            )}
                                        </FormattedMessage>

                                        {TunisiaStates.map((el, index) => (
                                            <option value={el.id} key={index}>
                                                {intl.formatMessage({
                                                    id: el.id,
                                                })}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                    </FormattedMessage>
                    <div className="d-flex align-items-center mb-3">
                        <label className={`${ styles.description } color-white mr-5`}>
                            <FormattedMessage id="gender" />
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioBox label="male" defaultValue={gender} onChange={setGender} />
                        </div>
                    </div>

                    <DateMonthYearPicker defaultValue={formatBirthday(birthday)} onChange={onDateChange} />

                    {formError ? <FormError messageId={formError} /> : null}
                    <button type="submit" className="mb-4 mt-4">
                        {updateProfileLoader ? (
                            <Loader fill={LoaderInButtonFillColor} />
                        ) : (
                            <FormattedMessage id="register-modification" />
                        )}
                    </button>
                </form>
            </div>

            <ChangePassword 
                onClose={closeChangePassword} 
                show={showChangePassword} 
            />
            <ChangeEmailOrPhonePopup 
                onClose={closeChangeEmailPhone} 
                shouldShow={showChangeEmailPhone} 
                phone={initialData?.phone || null}
                email={initialData?.email || null}
                onSuccess={() => {
                    closeChangeEmailPhone();
                }}
            />
        </PageTransitionWrapper>
    );
}

const formatBirthday = (birthday) => {
    if (!birthday) {
        return;
    }

    const [year, month, day] = birthday.split('-');

    return { year, month: String(parseInt(month) - 1), day };
};

export default EditProfile;
