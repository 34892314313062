import { useRef, useState } from 'react';
import { z } from 'zod';
import { FormattedMessage, useIntl } from 'react-intl';
import Countdown from 'react-countdown';

import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import { WithPagesForwardedActions } from 'components/widgets/draggable-popup/DraggablePopupPages';
import useInputState from 'hooks/useInputState';
import useToast from 'components/widgets/toast/useToast';
import TaooServerClient from 'http/axios';
import { endpoints } from 'constants/api';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import OtpInput from 'components/form/otp-input';

import './ChangeEmailOrPhonePopup.scss';
import { getStorageItem, setStorageItem } from 'services/storage';

interface Props {
    phone: string | null;
    email: string | null;
    shouldShow: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const notEmptySchema = z.string().min(1);
const mailSchema = z
        .string()
        .min(1)
        .email();
const phoneSchema = z
        .string()
        .min(8)
        .max(8);

const ChangeEmailOrPhonePopup = ({
    phone, email, shouldShow, onClose, onSuccess,
}: Props) => {
    const intl = useIntl();
    const { triggerToast } = useToast();
    const pagesRef = useRef<WithPagesForwardedActions>();
    const [challenge, setChallenge] = useState('');
    const [login, onChangeLogin, setLogin] = useInputState('');
    const [otpValue, setOtpValue] = useState('');
    const [isLoading, setLoader] = useState(false);
    const [countdownApi, setCountdownApi] = useState(null);
    const [countdown, setCountdown] = useState(Date.now() + 1000 * 60);
    const [shouldEnableResendCode, setShouldEnableResendCode] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);

    const onChange = (value) => setOtpValue(value);
    const nextPage = () => pagesRef.current?.paginateNext();
    // const previousPage = () => pagesRef.current?.paginatePrevious();

    const handleSendOtp = () => {
        if (!shouldEnableResendCode || isResendLoading) {
            return;
        }

        setIsResendLoading(true);

        const isEmail = getStorageItem('change-email-phone') === 'email';

        TaooServerClient.post(endpoints.auth.resendOtp, {
            login,
            action: isEmail ? 'UPDATE_EMAIL' : 'UPDATE_PHONE',
        })
                .then((result) => {
                    if (!result || !result?.data) {
                        throw new Error('API Error!');
                    }

                    setChallenge(result.data?.challenge ?? '');
                })
                .catch((err) => {
                    console.error(err.response);
                    setIsResendLoading(false);
                })
                .finally(() => {
                    setIsResendLoading(false);
                    setCountdown(Date.now() + 1000 * 60);
                    setShouldEnableResendCode(false);
                    countdownApi.start();
                });
    };

    const onVerify = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isEmpty = notEmptySchema.safeParse(login);

        if (!isEmpty.success) {
            triggerToast(intl.formatMessage({ id: 'error.missingField' }), 'warn');

            return;
        }

        // verify login
        const isEmail = mailSchema.safeParse(login);
        const isPhone = phoneSchema.safeParse(login);

        if (!isEmail.success && !isPhone.success) {
            triggerToast(intl.formatMessage({ id: 'error.email-or-phone' }), 'warn');

            return;
        }

        // check if value is new or old
        if (
            (isEmail.success && login === email) || (
                isPhone.success && login === phone
            )
        ) {
            triggerToast(intl.formatMessage(
                { id: 'error.email-or-phone.same-as-old' },
                { propName: isEmail.success ? 'e-mail' : 'numéro' },
            ), 'warn');

            return;
        }

        setStorageItem('change-email-phone', isEmail.success ? 'email' : 'phone');

        setLoader(true);

        TaooServerClient.post(endpoints.auth.resendOtp, {
            login,
            action: isEmail.success ? 'UPDATE_EMAIL' : 'UPDATE_PHONE',
        })
                .then((response) => {
                    if (!response || !response?.data) {
                        throw new Error('API Error');
                    }

                    setChallenge(response.data?.challenge ?? '');
                    nextPage();
                })
                .finally(() => setLoader(false));
    };

    const onCheckPin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoader(true);

        const isEmail = getStorageItem('change-email-phone') === 'email';

        TaooServerClient.post(endpoints.auth.otp, {
            otp:    otpValue,
            challenge,
            action: isEmail ? 'UPDATE_EMAIL' : 'UPDATE_PHONE',
        })
                .then((res) => {
                    if (!res?.data || res?.data?.success === false || res?.data?.error === 'RUNTIME_ERROR') {
                        return;
                    }

                    const { status = null } = res.data;

                    if (status !== 'ACTIVE') {
                        triggerToast(intl.formatMessage({ id: 'profile.change-email-or-phone.error' }), 'error');

                        const err = new Error('ACCOUNT_COULD_NOT_ACTIVATE');

                        // @ts-ignore
                        err.status = 500;

                        throw err;
                    }

                    triggerToast(intl.formatMessage({ id: 'profile.change-email-or-phone.success' }), 'success');
                    setLogin('');
                    setOtpValue('');
                    onSuccess?.();
                })
                .finally(() => setLoader(false));
    };

    const setRef = (countdown) => {
        if (countdown) {
            setCountdownApi(countdown.getApi());
        }
    };

    return (
        <DraggablePopup
            show={shouldShow}
            shouldShowHandle
            closeOnBackdropClick
            isDraggable
            wrapperStyle={{ height: 'fit-content', minHeight: 'unset', maxHeight: '100%' }}
            onClose={onClose}
        >
            <DraggablePopup.PagesContainer
                canSwipeBetweenPages={false}
                ref={pagesRef}

            >
                <form onSubmit={onVerify} className="email-phone-form">
                    <h2>{
                        intl.formatMessage({
                            id: 'profile.change-email-or-phone.title',
                        })
                    }</h2>
                    <div className="ep-f-group">
                        <p><FormattedMessage id="profile.change-email-or-phone.old-email" /></p>
                        <div className="input-wrapper">
                            <input
                                value={email}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="ep-f-group">
                        <p><FormattedMessage id="profile.change-email-or-phone.old-phone" /></p>
                        <div className="input-wrapper">
                            <input
                                value={phone}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="ep-f-group">
                        <p><FormattedMessage id="profile.change-email-or-phone.new" /></p>
                        <div className="input-wrapper">
                            <input
                                name="login"
                                type="text"
                                placeholder={intl.formatMessage({
                                    id: 'profile.change-email-or-phone.input-placeholder',
                                })}
                                value={login}
                                onChange={onChangeLogin}
                            />
                        </div>
                    </div>

                    <button disabled={isLoading} type="submit" className="type-submit">
                        {isLoading ? (
                            <Loader fill={LoaderInButtonFillColor} />
                        ) : intl.formatMessage({
                            id: 'profile.change-email-or-phone.button',
                        })}
                    </button>
                </form>
                <form className="validate-otp-form" onSubmit={onCheckPin}>
                    <h3 className="mb-3">
                        <FormattedMessage id="checkpin.security_code" />
                    </h3>
                    <p>
                        <FormattedMessage id="checkpin.enter_otp_msg" />
                    </p>
                    <OtpInput value={otpValue} valueLength={4} onChange={onChange} />

                    <div className="vo-resend">
                        <span
                            aria-disabled={!shouldEnableResendCode || isResendLoading}
                            className={`vo-inbox ${ !shouldEnableResendCode || isResendLoading ? '' : 'active' }`}
                            onClick={handleSendOtp}
                        >
                            <FormattedMessage id="checkpin.check_inbox_msg_resend" />
                        </span>

                        {isResendLoading && (
                            <Loader
                                wrapperStyle={{
                                    width:       '1.3rem',
                                    height:      '1.3rem',
                                    margin:      '0',
                                    marginRight: '0.5rem',
                                }}
                            />
                        )}
                        {countdown > 0 && (
                            <Countdown
                                ref={setRef}
                                className="countdown"
                                date={countdown}
                                autoStart
                                renderer={renderer}
                                onComplete={() => {
                                    setShouldEnableResendCode(true);
                                    setCountdown(0);
                                }}
                            />
                        )}
                    </div>

                    <button disabled={isLoading} type="submit" className="my-4 button type-submit">
                        {isLoading ?
                            <Loader fill={LoaderInButtonFillColor} /> :
                            <FormattedMessage id="checkpin.submit_form" />}
                    </button>
                </form>
            </DraggablePopup.PagesContainer>
        </DraggablePopup >
    );
};

const renderer = ({ minutes, seconds }) => (
    <span className="countdown">
        {minutes}:{seconds}
    </span>
);

export default ChangeEmailOrPhonePopup;
