import { useDispatch, useSelector } from 'react-redux';

import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { getProfile, updateProfileInfo } from 'store/profile/profile.action';

export default function useUpdateProfileInfo() {
    const dispatch = useDispatch();
    const { isUpdated, infoUpdateError, infoUpdateIsLoading } = useSelector((state) => state.profileReducer);

    const update = (data) => {
        dispatch(updateProfileInfo(data));
    };

    useUpdateEffect(() => {
        dispatch(getProfile());
    }, [isUpdated]);

    return {
        update,
        isUpdated,
        error:     infoUpdateError,
        isLoading: infoUpdateIsLoading,
    };
}
