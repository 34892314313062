import { CircularProgressbar } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';

import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { progressBarStyles } from 'constants/index';

import styles from './index.module.scss';

function ProfileEditAvatar({ image = null }) {
    const { isLoading, progress } = useSelector((state) => {
        const { data, InfoIsLoading, avatarUpdateIsLoading } = state.profileReducer;
        const progress = data && 'completedProfile' in data ? data.completedProfile : 0;

        return {
            isLoading: InfoIsLoading || avatarUpdateIsLoading,
            progress,
        };
    });

    return (
        <div className={`${ styles['avatar-container'] } avatar-container pos-relative mx-auto`}>
            <CircularProgressbar strokeWidth={3} value={progress} styles={progressBarStyles} />
            <div className={`${ styles.avatar } bold color-white`}>
                <span className={styles['img-wrapper']}>{isLoading && !image ? <Loader /> : <Img src={image} />}</span>
            </div>
        </div>
    );
}

export default ProfileEditAvatar;
