import { FormattedMessage } from 'react-intl';

import './index.scss';

function FormError({ messageId }) {
    return messageId
        ? (
            <p className="form-error">
                <FormattedMessage id={messageId} />
            </p>
        )
        : null;
}

export default FormError;
